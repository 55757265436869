<template>
  <div>
    <template>
      <v-container>
        <SettingsGear :showIcon="true" />
        <div style="height: 15vh">
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 2vh;
            "
          >
            <img src="@/assets/logos/dime_logo_gold.png" style="height: 7em" />
          </div>
        </div>
        <div style="height: 66vh">
          <div style="margin-top: 10vh"></div>
          <div class="edit-content" v-show="isConnected && isInsert">
            <div
              style="
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
              "
            >
              <img
                :src="pasteInfo.icon"
                :style="`height: 2em; margin-left: 1.25em; margin-top: 0.5em;margin: 0 auto;`"
              />
            </div>
            <div
              class="edit-title text-uppercase fw-bold white--text text-center"
            >
              {{ pasteInfo.name }}
            </div>
            <div class="edit-set-main">
              <img
                class="set-main-button"
                src="@/assets/ui/icon_top.png"
                @click="onClickTop"
              />
              <div class="set-main-item">
                <div class="main-item-value">{{ isVoltage ? (pasteInfo.value / 1000).toFixed(2)  : pasteInfo.value }}</div>
                <div v-if="isVoltage" class="main-item-content">
                  <span class="item-content-right">V</span>
                </div>
                <div v-else class="main-item-content">
                  <span class="item-content-right">°</span>
                  <span class="item-content-right">F</span>
                </div>
              </div>
              <img
                class="set-main-button"
                src="@/assets/ui/icon_down.png"
                @click="onClickDown"
              />
            </div>
            <div
              class="
                edit-confirm
                text-uppercase text-center
                fw-bold
                white--text
              "
              @click="onClickConfirm"
            >
              confirm
            </div>
            <div
              class="edit-msg text-uppercase text-center fw-bold white--text"
              @click="onClickResetToDefault"
            >
              reset to default
            </div>
          </div>
        </div>
        <Footer />
      </v-container>
    </template>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import { Toast } from "vant";
import StoreType from "../store/bluetooth/StoreType";
import cartridge from "../api/cartridge"

export default {
  name: "editPresets",
  data() {
    return {
      pasteInfo: {
        icon: this.$store.getters.selectedPresetItem.icon,
        id: this.$store.getters.selectedPresetItem.id,
        customName:
          this.$store.getters.selectedPresetItem.heatingModeList[0]
            .operatingModeName,
        name: this.$store.getters.selectedPresetItem.pasteTypeName,
        value:
          this.$store.getters.selectedPresetItem.heatingModeList[0].temperature.split(
            ","
          )[0],
      },
      isResetClick: false
    };
  },
  created() {},
  methods: {
    ...mapActions(["_setPresetInfo"]),
    onClickTop() {
      if (this.isVoltage) {
        if (parseFloat(this.pasteInfo.value) >= 4200) {
          // Toast("Temperature cannot be greater than 1000.");
          this.$showTips({
            msg: "The voltage cannot be greater than 4.2V.",
            duration: 3000,
            showType: "info",
          });
          return;
        }
        this.pasteInfo.value = (parseFloat(this.pasteInfo.value) + 50).toString();
      }else {

        if (parseFloat(this.pasteInfo.value) > 995) {
          // Toast("Temperature cannot be greater than 1000.");
          this.$showTips({
            msg: "Temperature cannot be greater than 1000.",
            duration: 3000,
            showType: "info",
          });
          return;
        }
        this.pasteInfo.value = (parseFloat(this.pasteInfo.value) + 5).toString();
      }
    },
    onClickDown() {
      if (this.isVoltage) {
        if (parseFloat(this.pasteInfo.value) <= 2000) {
          // Toast("Temperature cannot be greater than 1000.");
          this.$showTips({
            msg: "The voltage cannot be less than 2.0V.",
            duration: 3000,
            showType: "info",
          });
          return;
        }
        this.pasteInfo.value = (parseFloat(this.pasteInfo.value) - 50).toString();
      }else {
        if (parseFloat(this.pasteInfo.value) < 205) {
          // Toast("The temperature cannot be less than 200.");
          this.$showTips({
            msg: "The temperature cannot be less than 200.",
            duration: 3000,
            showType: "info",
          });
          return;
        }
        this.pasteInfo.value = (parseFloat(this.pasteInfo.value) - 5).toString();
      }
    },
    onClickConfirm() {
      if (this.pasteInfo.value !== this.oldValue) {
        this.$store.commit(StoreType.COMMIT_TYPE.STATE_PRESET_IS_CHANGED, true);
      }
      let presetTypes = this.presetTypes;
      let i = 0;
      for (let j = 0; j < presetTypes.length; j++) {
        if (presetTypes[j].id === this.selectedPresetItem.id) {
          i = j;
          break;
        }
      }

      let t = [];
      for (let j = 0; j < 10; j++) {
        t.push(this.pasteInfo.value);
      }
      presetTypes[i].heatingModeList[0].temperature = t.join(",");
      this.$store.commit(
        StoreType.COMMIT_TYPE.STATE_PRESET_FROM_SERVER,
        presetTypes
      );
      this.$router.back();
    },
    onClickResetToDefault() {
      this.showLoading("Loading")
      cartridge.resetCustomDiyTemperature(this.id,this.podId).then((data)=> {
        this.pasteInfo.value = data.split(",")[0];
        this._setPresetInfo(this.pasteInfo);

        this.$store.commit(StoreType.COMMIT_TYPE.STATE_PRESET_IS_CHANGED, true);
        let presetTypes = this.presetTypes;
        let i = 0;
        for (let j = 0; j < presetTypes.length; j++) {
          if (presetTypes[j].id === this.selectedPresetItem.id) {
            i = j;
            break;
          }
        }

        let t = [];
        for (let j = 0; j < 10; j++) {
          t.push(this.pasteInfo.value);
        }
        presetTypes[i].heatingModeList[0].temperature = t.join(",");
        this.$store.commit(
            StoreType.COMMIT_TYPE.STATE_PRESET_FROM_SERVER,
            presetTypes
        );
        this.$store.dispatch("changeTemperatureMode");


        this.hideLoading()
      })
    },
  },
  computed: {
    ...mapGetters([
      "getMainColor",
      "getAccentColor",
      "presetTypes",
      "selectedPresetItem",
      "originPresetItem",
      "podId",
      "isInsert",
      "isConnected",
      "isVoltage",
    ]),

    oldValue() {
      return this.selectedPresetItem.heatingModeList[0].temperature.split(
        ","
      )[0];
    },
  },
  watch: {
    isConnected(oldValue,newValue) {
      if (newValue) {
         this.$router.back()
      }
    },
    isInsert(oldValue,newValue) {
      this.$router.back()
    }
  }
};
</script>

<style lang="less" scoped>
.edit-content {
  .edit-title {
    font-size: 4vh;
  }

  .edit-set-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 3vh;

    .set-main-button {
      width: 23px;
    }
  }

  .set-main-item {
    height: 6.5vh;
    border: 2px solid #ffab00;
    border-radius: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2vh 0;
    position: relative;

    .main-item-value {
      color: #ffffff;
      font-size: 2em;
      font-weight: bold;
      position: absolute;
      width: 100%;
      text-align: center;
    }

    .main-item-content {
      display: flex;
      flex-direction: column;
      margin-left: 12vh;

      .item-content-right {
        color: #ffffff;
        font-size: 3.1vh;
        font-weight: bold;
        line-height: 0.5;
        text-align: center;
      }
    }
  }

  .edit-confirm {
    height: 6.5vh;
    width: 100%;
    background: #ffab00;
    font-size: 1.5em;
    border-radius: 25px;
    margin-top: 3vh;
    line-height: 6.5vh;
  }

  .edit-msg {
    margin-top: 1vh;
    font-size: 1.5em;
  }
}
</style>
